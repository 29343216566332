import {HttpResponse} from './models/HttpResponse'

const HelpHttp = () => {
  const customFetch = (endpoint: string, options: any, timeout?: number) => {
    const defaultHeader = {
      accept: 'application/json',
    }

    const controller = new AbortController()
    options.signal = controller.signal

    options.headers = options.headers ? {...defaultHeader, ...options.headers} : defaultHeader

    if (
      typeof options !== 'undefined' &&
      options !== null &&
      typeof options.body !== 'undefined' &&
      options.body !== null
    ) {
      options.body = options.body instanceof FormData ? options.body : JSON.stringify(options.body)
    }

    if (typeof timeout !== 'undefined' && timeout !== null) {
      setTimeout(() => controller.abort(), typeof timeout !== 'undefined' ? timeout : 10000)
    }

    return fetch(endpoint, options)
  }

  const prepareResponse = (response: Response, message204: string): Promise<HttpResponse> => {
    if (typeof response !== 'undefined' && response !== null) {
      if (response.ok && response.status !== 204) {
        if (response.headers.get('content-type') !== null) {
          return response
            .json()
            .then(
              (dataJson: any) =>
                new HttpResponse({
                  ok: true,
                  data: dataJson,
                  statusCode: response.status,
                })
            )
            .catch((error: any) => {
              return new Promise<HttpResponse>((resolve) => {
                resolve(
                  new HttpResponse({
                    error: true,
                    errors: ['Unexpected error processing json'],
                    statusCode: response.status,
                  })
                )
              })
            })
        } else {
          return new Promise<HttpResponse>((resolve) => {
            resolve(
              new HttpResponse({
                ok: true,
                data: null,
                statusCode: response.status,
              })
            )
          })
        }
      }

      if (response.status === 400) {
        try {
          return response
            .json()
            .then(
              (errorJson: any) =>
                new HttpResponse({
                  error: true,
                  errors: errorJson.errors,
                  statusCode: response.status,
                })
            )
            .catch((error: any) => {
              console.log(error)
              return new Promise<HttpResponse>((resolve) => {
                resolve(
                  new HttpResponse({
                    error: true,
                    errors: ['Unexpected error processing json'],
                    statusCode: response.status,
                  })
                )
              })
            })
        } catch (error) {
          console.log(error)
          return new Promise<HttpResponse>((resolve) => {
            resolve(
              new HttpResponse({
                error: true,
                errors: ['Unexpected error processing 400 response'],
                statusCode: response.status,
              })
            )
          })
        }
      }

      if (response.status === 500) {
        return new Promise<HttpResponse>((resolve) => {
          resolve(
            new HttpResponse({
              unauthorized: true,
              errors: ['Unexpected error'],
              statusCode: response.status,
            })
          )
        })
      }

      if (response.status === 401 || response.status === 403) {
        return new Promise<HttpResponse>((resolve) => {
          resolve(
            new HttpResponse({
              unauthorized: true,
              errors: ['Unauthorized'],
              statusCode: response.status,
            })
          )
        })
      }

      if (response.status === 204) {
        return new Promise<HttpResponse>((resolve) => {
          resolve(
            new HttpResponse({
              error: true,
              errors: [message204],
              statusCode: response.status,
            })
          )
        })
      }
    }

    return new Promise<HttpResponse>((resolve) => {
      resolve(
        new HttpResponse({
          unexpectedError: true,
          errors: ['Unexpected error'],
          statusCode: response.status,
        })
      )
    })
  }

  return {
    customFetch,
    prepareResponse,
  }
}

export default HelpHttp
