import HelpHttp from "../../../common/HelpHttp"
const API_URL = process.env.REACT_APP_API_URL

export const getModels = async (clientKey: string | undefined | null) => {
  const response = await fetch(`${API_URL}/model/get-models`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'ClientKey': clientKey || '',
    },
  })

  if (!response.ok) {
    throw new Error('Failed to fetch models')
  }

  return await response.json()
}

export const deleteModel = async (modelId: string, clientKey: string | undefined | null) => {
  const response = await fetch(`${API_URL}/model/delete/${modelId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'ClientKey': clientKey || '',
    },
  })

  if (!response.ok) {
    throw new Error('Failed to delete model')
  }

  return await response.json()
}

export const saveModel = async (modelType: string, clientKey: string | undefined | null) => {
  const response = await fetch(`${API_URL}/prediction/save-model`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ClientKey': clientKey || '',
    },
    body: JSON.stringify({ ModelType: modelType }),
  })

  if (!response.ok) {
    throw new Error('Failed to save model')
  }

  return await response.json()
}

export const clearModelCache = async (clientKey: string | undefined | null) => {
  const response = await fetch(`${API_URL}/prediction/clear-cache`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'ClientKey': clientKey || '',
    },
  })

  if (!response.ok) {
    throw new Error('Failed to clear model cache')
  }

  return await response.json()
}

export const getModelDetails = async (modelId: string, clientKey: string | undefined | null) => {
  const response = await fetch(`${API_URL}/model/model-details/${modelId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'ClientKey': clientKey || '',
    },
  })
  console.log('response', response)

  if (!response.ok) {
    throw new Error('Failed to fetch model details')
  }

  return await response.json()
}

export const getSensorDataAvailability = (token: string, clientKey: string | undefined, circuitId: string, year: number): Promise<Date[]> => {
  const REQUEST_URL = `${API_URL}/sensor/sensor/data-availability/${circuitId}/${year}`

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      ClientKey: clientKey || '',
    },
  }

  return HelpHttp()
    .customFetch(REQUEST_URL, options)
    .then((response: any) => {
      return HelpHttp().prepareResponse(response, 'Failed to fetch sensor data availability')
    })
    .then((data: { data: string[] }) => {
      return data.data.map(dateString => {
        const [year, month, day] = dateString.split('T')[0].split('-').map(Number);
        return new Date(year, month - 1, day); // Note: month is 0-indexed in JavaScript Date
      });
    });
}
export interface ValidationResult {
  r2_score: number;
  mape: number;
  rmse: number;
}

export const getValidationResults = async (
  modelId: string,
  startDate: string,
  endDate: string,
  clientKey: string | undefined | null
): Promise<ValidationResult> => {
  const response = await fetch(`${API_URL}/prediction/validate-model`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ClientKey: clientKey || '',
    },
    body: JSON.stringify({ ModelId: modelId, StartDate: startDate, EndDate: endDate }),
  });

  if (!response.ok) {
    throw new Error('Failed to validate model');
  }

  return await response.json();
};