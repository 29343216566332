export class HttpResponse {
  public ok: boolean = false
  public data: any | null = null
  public unauthorized: boolean = false
  public error: boolean = false
  public unexpectedError: boolean = false
  public errors: any | object | null = null
  public statusCode: number = 0

  constructor(fields?: {
    ok?: boolean
    data?: any | null
    unauthorized?: boolean
    error?: boolean
    unexpectedError?: boolean
    errors?: any | null
    statusCode?: number
  }) {
    if (fields) Object.assign(this, fields)
  }
}
