// ModelValidationPage.tsx

import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../auth'
import {getModels, getValidationResults, ValidationResult} from './services/ModelService'
import {toast} from 'react-hot-toast'

const ModelValidationPage: React.FC = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const [models, setModels] = useState<any[]>([])
  const [selectedModel, setSelectedModel] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [validationResults, setValidationResults] = useState<ValidationResult | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [dateErrors, setDateErrors] = useState({start: false, end: false})

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const fetchedModels = await getModels(auth?.clientKey)
        setModels(fetchedModels)
      } catch (error) {
        console.error('Error fetching models:', error)
        toast.error(intl.formatMessage({id: 'VALIDATION.MODELS_FETCH_ERROR'}))
      }
    }
    fetchModels()
  }, [auth?.clientKey])

  const handleValidate = async () => {
    const newDateErrors = {start: !startDate, end: !endDate}
    setDateErrors(newDateErrors)

    if (newDateErrors.start || newDateErrors.end || !selectedModel) {
      toast.error(intl.formatMessage({id: 'VALIDATION.FILL_ALL_FIELDS'}))
      return
    }

    setIsLoading(true)
    try {
      const results = await getValidationResults(selectedModel, startDate, endDate, auth?.clientKey)
      setValidationResults(results)
    } catch (error) {
      console.error('Validation failed:', error)
      toast.error(intl.formatMessage({id: 'VALIDATION.VALIDATION_FAILED'}))
    } finally {
      setIsLoading(false)
    }
  }

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(e.target.value)
    setValidationResults(null)
  }

  const handleDateChange =
    (dateType: 'start' | 'end') => (e: React.ChangeEvent<HTMLInputElement>) => {
      const setDate = dateType === 'start' ? setStartDate : setEndDate
      setDate(e.target.value)
      setDateErrors((prev) => ({...prev, [dateType]: false}))
    }

  const selectedModelInfo = models.find((model) => model.id === selectedModel)

  const renderModelInfo = () => {
    if (!selectedModelInfo) return null

    return (
      <div className='mb-6'>
        <h4>{intl.formatMessage({id: 'VALIDATION.SELECTED_MODEL_INFO'})}</h4>
        <p>
          {intl.formatMessage({id: 'VALIDATION.MODEL_ACCURACY'})}:{' '}
          {(selectedModelInfo.accuracy * 100).toFixed(2)}%
        </p>
        <p>
          {intl.formatMessage({id: 'VALIDATION.MODEL_DATE_RANGE'})}:{' '}
          {formatDate(selectedModelInfo.inputInfo.startDate)} -{' '}
          {formatDate(selectedModelInfo.inputInfo.endDate)}
        </p>
      </div>
    )
  }

  const renderValidationResults = () => {
    if (!validationResults) return null

    return (
      <div className='mt-6'>
        <h4>{intl.formatMessage({id: 'VALIDATION.RESULTS'})}</h4>
        <p>
          {intl.formatMessage({id: 'VALIDATION.MODEL_ACCURACY'})}:{' '}
          {(validationResults.r2_score * 100).toFixed(2)}%
        </p>
      </div>
    )
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
  }

  return (
    <>
      <PageTitle
        breadcrumbs={breadCrumbs(
          intl.formatMessage({id: 'MENU.VALIDATION'}),
          auth?.clientKeyName || ''
        )}
      >
        {intl.formatMessage({id: 'MENU.VALIDATION'})}
      </PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body'>
          <div className='row mb-6'>
            <div className='col-lg-4'>
              <label className='form-label'>
                {intl.formatMessage({id: 'VALIDATION.SELECT_MODEL'})}
              </label>
              <select className='form-select' value={selectedModel} onChange={handleModelChange}>
                <option value=''>
                  {intl.formatMessage({id: 'VALIDATION.SELECT_MODEL_OPTION'})}
                </option>
                {models.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-lg-4'>
              <label className='form-label'>
                {intl.formatMessage({id: 'VALIDATION.START_DATE'})}
              </label>
              <input
                type='date'
                className={`form-control ${dateErrors.start ? 'is-invalid' : ''}`}
                value={startDate}
                onChange={handleDateChange('start')}
              />
            </div>
            <div className='col-lg-4'>
              <label className='form-label'>
                {intl.formatMessage({id: 'VALIDATION.END_DATE'})}
              </label>
              <input
                type='date'
                className={`form-control ${dateErrors.end ? 'is-invalid' : ''}`}
                value={endDate}
                onChange={handleDateChange('end')}
              />
            </div>
          </div>
          {renderModelInfo()}
          <button className='btn btn-primary' onClick={handleValidate} disabled={isLoading}>
            {isLoading
              ? intl.formatMessage({id: 'VALIDATION.VALIDATING'})
              : intl.formatMessage({id: 'VALIDATION.VALIDATE'})}
          </button>
          {renderValidationResults()}
        </div>
      </div>
    </>
  )
}

export default ModelValidationPage

const breadCrumbs = (title: string, clientName: string) => {
  const crumbs = [
    {
      title: title,
      path: '/pages/validation',
      isSeparator: false,
      isActive: false,
    },
    {
      title: clientName.trim() || '',
      path: '/pages/validation',
      isSeparator: !clientName.trim(),
      isActive: true,
    },
  ]

  return crumbs
}
